import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "./../Stringify";
import GroupButton from "../../libs/GroupButton/GroupButton";
import MyModal from "../../libs/MyModal";
import {STATUS_ITEMS} from "../Main/ZadachiStatuses";
import {getODBByInfo} from "../Main/ZadachiDate";
import Smart from "../../libs/Smart";
import {ZametkiEdit} from "../Main/Zametki";


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);

    let [isModal, setIsModal] = useState(false);
    let [open, setOpen] = useState(false);
    let [_info, setInfo] = useState({})
    let [tempTask, setTempTask] = useState(null)

    let {item, onChange, onChangeFilter} = props;
    let stats = item?.smartReadingStats;

    let {groupsShort, groups, info} = item;
    groupsShort = groupsShort || []
    let infograph = item.infographic;

    let sumInd = groups.length - 1;

    _.each(groups, (item, ind) => {
        let arr0 = (item?.arr || [])[0] || {};
        let text = arr0?.text;
        if (/10 лучших мыслей/gi.test(text)) {
            sumInd = ind;
        }
    })

    function onClickItem(v) {
        let text = v.item?.html || v.item;
        let type = v.type;
        let el = document.createElement('div');
        el.innerHTML = text;

        let specArr = [];
        let otherArr = [];
        el.querySelectorAll('span').forEach(it => {
            console.log("qqqqq ittttttttttttt", it);
            specArr.push(('' + it.innerText).trim(''));
            it.innerHTML = '<div></div>'
        })
        otherArr = [(el.innerText || '').trim('')]

        let title1 = specArr.filter(it => it.length > 5).join('\n')
        let title2 = otherArr.filter(it => it.length > 5).join('\n')
        if (!title1) {
            title1 = title2;
            title2 = null;
        }
        title1 = title1 || ''
        title2 = title2 || ''
        let cr_key = [title1, title2].join('|')
        console.log("qqqqq onCLick item", {title1, title2, otherArr, specArr}, v);
        setIsModal(true)

        global.http.get('/skill', {per_page: 100, filter: {creationBookKey: cr_key}}).then(r => {
            console.log("qqqqq rrrrrrrrrrrrrrrrrrrrr4444", r.items);
            setTempTask((r.items || [])[0] || {creationBookKey: cr_key,
                isStory: type === 'quote',
                categories: item.categories, name: title1, desc: title2})
        })


        // let taskKeys = item?.taskKeys || {}
        // taskKeys[cr_key] = taskKeys[cr_key] || {status: 'pending'}

        onChange({...item})


    }
    info = info || {}

    return <div>
        <MyModal
            isOpen={tempTask}
            onClose={() => {
                setTempTask(false)
            }}
        >
            {isModal?.title1} {tempTask?.isStory ? 'STORY': 'NOT A STORY'}
            <hr/>
            <ZametkiEdit item={tempTask} setItem={(v, v1) => {
                console.log("qqqqq vvvvvvvvvvvvvvvvvvvvv", v, v1, Boolean(!v && v1 && v1?.creationBookKey));
                setTempTask(v)
                if (Boolean(!v && v1 && v1?.creationBookKey)) {
                    let cr_key = v1?.creationBookKey
                    let taskKeys = item?.taskKeys || {}
                    // taskKeys = {};
                    taskKeys[cr_key] = taskKeys[cr_key] || {_id: v1._id}
                    onChange({...item, taskKeys})
                }
            }}></ZametkiEdit>
            {/*<Smart*/}
            {/*    defSize={12}*/}
            {/*    obj={tempTask}*/}
            {/*    items={[*/}

            {/*        {*/}

            {/*            isMulti: true,*/}
            {/*            path: 'Main/MyAutocomplete',*/}
            {/*            key: 'categories',*/}
            {/*            itemsKey: 'categories',*/}
            {/*            label: 'Категория',*/}
            {/*        },*/}
            {/*        {key: 'name', type: 'textarea', minRows: 1},*/}
            {/*        {key: 'desc', type: 'textarea'},*/}

            {/*    ]}*/}
            {/*    onChange={(v) => setTempTask({...v})}*/}
            {/*>*/}

            {/*</Smart>*/}
            {/*<Stringify item={(item?.taskKeys || {})[tempTask?.cr_key]}></Stringify>*/}
            {/*<Stringify item={tempTask}></Stringify>*/}
        </MyModal>
        {(item.smartReadingCategories || []).map((it, ind) => {
            return (<a key={ind} className={'mr-5'} onClick={() => {
                onChangeFilter({categories: [it.href]})
            }}>
                {it.title}
            </a>)
        })}
        <div></div>
        <GroupButton items={['', 'read', 'great'].map(it => {
            return {name: it || '-', value: it}
        })} value={item.status}
                     onChange={(v, a, b) => {
                         onChange({status: v})
                     }}></GroupButton>
        <GroupButton items={[0, 1, 2, 3, 4, 5].map(it => {
            let v = it.toString();
            return {value: v, name: v}
        })} value={item.rate}
                     onChange={(v, a, b) => {
                         onChange({rate: v})
                     }}
        ></GroupButton>
        <div className={'mt-5'}>
            {stats?.views} {stats?.likes}
            <div className={'ib ml-5'}>
                <div>
                    {(item.authors || []).map((it, ind) => {
                        return (<a key={ind} href={it.href} className={'mr-5'}>
                            {it.text}

                        </a>)
                    })}

                </div>


            </div>
        </div>
        <div style={{fontSize: '135%'}}>
            {!item.status && item.smartReadingStatus === 'new' ?
                <small className={'label label-info mr-5 '}>NEW (SM + STATUS)</small> : ''}
            <a href={item.link} target={"_blank"}>{item.name}</a>
        </div>

        <Smart
            defSize={6}
            obj={item}
            items={[
                {
                    isMulti: true,
                    path: 'Main/MyAutocomplete',
                    key: 'categories',
                    itemsKey: 'categories',
                    label: 'Категория',
                },
            ]}
            onChange={(v) => onChange({...v})}
        >

        </Smart>
        <hr/>

        <div className={'grouppingItems'}>
        <RenderGroup group={(groupsShort[info?.shortInd || info?.shortAutoInd] || {})}
                     onClickItem={onClickItem}></RenderGroup>
        <div style={{marginTop: '10px'}}>
            <RenderGroup group={(groups[sumInd] || {})} onClickItem={onClickItem}></RenderGroup>
        </div>

        <hr/>
        <QuotesList item={item} onClickItem={onClickItem}></QuotesList>
        <ImgsList item={item} onClickItem={onClickItem}></ImgsList>
        <InfographicLink item={item} onClickItem={onClickItem}></InfographicLink>
        <hr/>
        {(groups || []).map((it, ind) => {
            let arr0 = it.arr[0] || {};
            return (<div key={ind}>
                <RenderCollapsedGroup ind={ind} item={it} onClickItem={onClickItem}></RenderCollapsedGroup>
            </div>)
        })}

        </div>
        <a onClick={() => {
            setOpen(!open)
        }}>open </a>

        <div style={{overflow: 'auto', maxHeight: '500px'}}>
            {open && <Stringify item={item}></Stringify>}
        </div>

    </div>
}

export function QuotesList(props) {
    let {item} = props
    let {quotes} = item || {}
    let [open, setOpen] = useState(false)
    console.log("qqqqq item quotes", item, quotes);
    return <div>
        {<div onClick={() => {
            setOpen(!open)
        }}>Quotes List {quotes.length}</div>}
        {open && <div>
            {(quotes || []).map((it, ind) => {
                return (<div key={ind} dangerouslySetInnerHTML={{__html: it.html}} onClick={() => {
                    props?.onClickItem({type: 'quote', item: it})
                }}>
                </div>)
            })}
        </div>}


    </div>
}

export function ImgsList(props) {
    let {item} = props
    let {imgs} = item || {}
    let [open, setOpen] = useState(false)
    console.log("qqqqq item quotes", item, imgs);
    return <div>
        {<div onClick={() => {
            setOpen(!open)
        }}>Images List {imgs.length}</div>}
        {open && <div>
            {(imgs || []).map((it, ind) => {
                return (<div key={ind}>
                    <img src={`${global.env.domain}/api/get-img?url=https://smartreading.ru${it.src}`}
                         onClick={() => props.onClickItem({type: 'img', item: it})}
                    />
                </div>)
            })}
        </div>}


    </div>
}

export function InfographicLink(props) {
    let {item} = props
    let {infographic} = item;
    let [open, setOpen] = useState(false)
    let {link} = infographic || {}
    if (!link) {
        return 'No Infograph'
    }
    let infoId = (link.match(/summary\/(.+?)\//i) || [])[1]

    // https://smartreading.ru/summary/127/infographics
    //
    let href = `https://smartreading.ru/file/media/${infoId}/INFOFRAPHICS/get/infographics_${infoId}.jpg`
    let src = `${global?.env.domain}/api/get-img?url=${href}`;
    return <>
        {link && <div onClick={() => {
            setOpen(!open)
        }}>Infographic YES:</div>}
        {open && infoId && <><img
            onClick={() => props.onClickItem({type: 'infograph', item: {src, infoId}})}
            src={src}/>
            <a href={href} target={"_blank"}>open</a>

        </>}
    </>
}

export function RenderCollapsedGroup(props) {
    let {item, ind} = props;
    let arr0 = (item?.arr || [])[0] || {};
    let text = arr0.text;

    let [open, setOpen] = useState(false)
    let [short, setShort] = useState(false)

    let calc = 0
    _.each(item.arr, (item, ind) => {
        calc += (item.text?.length || 0)
    })

    if (!calc && !text) {
        return;
    }
    let fullList = []
    let quotes = []
    _.each(item?.arr, (item, ind) => {
        _.each(item.bChilds, (item, ind) => {
            item !== text && fullList.push(item)
        })
        _.each(item.s2childs, (item, ind) => {
            item !== text && fullList.push(item)
        })
        if (item.tagName === "blockquote") {
            item?.text !== text && quotes.push(item?.text)
        }
        _.each(item.quotes, (item, ind) => {
            item?.text !== text && quotes.push(item)

        })

    })


    return <div>
        <strong onClick={() => {
            console.log("qqqqq item group", item);
            setOpen(!open)
        }}>#{ind + 1}. [{calc}] {text}</strong>

        <div style={{paddingLeft: '25px'}}>

            {(_.sortBy(_.uniq(fullList), it => (-1) * it?.length) || []).map((it, ind) => {
                return (<div key={ind} className={'boldListItem'}
                             onClick={() => props.onClickItem({type: 'bold', item: it})}
                >
                    {it}
                </div>)
            })}
            {/*<div onClick={() => {*/}
            {/*    setShort(!short)*/}
            {/*}}>QUOTES: {quotes.length}</div>*/}
            {!short && (quotes || []).map((it, ind) => {
                return (<blockquote key={ind}
                                    onClick={() => props.onClickItem({type: 'quote', item: it})}
                >
                    {it}
                </blockquote>)
            })}
        </div>


        {open && <RenderGroup group={item}></RenderGroup>}
    </div>
}

export function RenderGroup(props) {
    let {group} = props;
    let arr = group?.arr || []
    return <div style={{borderLeft: '2px solid grey', paddingLeft: '10px'}}>
        {(arr || []).map((it, ind) => {
            return (<div key={ind}
                style={{fontSize: '18px'}}
                         dangerouslySetInnerHTML={{__html: it?.html}} onClick={() => {
                props.onClickItem({type: 'group', item: it})
            }}>
            </div>)
        })}


    </div>

}


export default Layout2
