import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "./../Stringify";
import BookShort from "./BookShort";
import './books.css'
import Select from "../../libs/Select";
import Input from "../../libs/Input";
import MyAutoComplete from "../Main/MyAutocomplete";
import Autocomplete from "@mui/material/Autocomplete";

export function useStateStorage(key, defaultValue) {
    // Retrieve the stored value from localStorage or use the default value
    const [state, setState] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    // Save the state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
}

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);

    let [isFullOpen1, setIsFullOpen1] = useState(false)
    let [isFullOpen2, setIsFullOpen2] = useState(false)
    let [filter, setFilter] = useStateStorage('filter', {})
    let [info, setInfo] = useState({})
    let [items, setItems] = useState([])
    let [selItem, setSelItem] = useState(null)
    let [categoriesObj, setCategoriesObj] = useState({})
    let [categories, setCategories] = useState([])


    useEffect(() => {
        global.http.get('/smart-book', {
            per_page: 10000,
            customSel: '_id name link numberOfSkills status categories smartReadingCategories rate smartReadingStatus smartReadingStats cd'
        }).then(r => {
            _.each(r.items, (item, ind) => {
                item.link === 'https://smartreading.ru/summary/1073/epub/read' && console.log("qqqqq item linkkkkkkkkk", item, item.link);
            })

            setInfo(r)
            setItems(r.items)
            let categoriesObj = {};
            _.each(r.items, (item, ind) => {
                _.each(item.smartReadingCategories, (item, ind) => {
                    categoriesObj[item.href] = {_id: item.href, ...item}
                })
            })
            setCategoriesObj(categoriesObj)
            setCategories(Object.keys(categoriesObj).map(it => {
                return categoriesObj[it]
            }))
            console.log("qqqqq categoriesObj", categoriesObj, r.items);
        })
    }, [])

    function onClickBook(it) {
        console.log("qqqqq on click book", it);
        setSelItem({loading: true})
        global.http.get(`/smart-book/${it._id}`, {}).then(r => {
            setSelItem(r)
            console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", r);
        })
    }

    let goodItems = [];
    let otherItems = [];
    let filterCategoriesObj = {};

    let counts = {good: {}, other: {}}


    _.each(filter.sort ? _.sortBy(items, item => {
        let stats = item?.smartReadingStats || {};
        if (filter.sort == 'likes') {
            return (-1) * (stats.likes || 0)
        }
        if (filter.sort == 'views') {
            return (-1) * (stats.views || 0)

        }
        if (filter.sort == 'cd') {
            return item.cd
        }

        if (filter.sort == 'rate') {
            return (-1) * (item.rate || 0)
        }
        return 1;
    }) : items, (item, ind) => {
        let stats = item?.smartReadingStats;
        let {name, status, smartReadingStatus, cd, rate} = item;

        let reg = filter.search ? new RegExp(filter.search, 'gi') : null;
        let isOk = true;


        let errors = []
        if (reg && !reg.test(name) && !reg.test(item.link || '')) {
            isOk = false;
            errors.push('reg')
        }

        if (!filter.search && filter.categories) {
            let isCat = false;
            _.each(filter.categories, (categoryId, ind) => {
                if ((item.smartReadingCategories || []).find(v => {
                    return categoryId == v.href;
                })) {
                    isCat = true;

                }

            })
            if (!isCat) {
                isOk = false;
                errors.push('category')
            }

        }
        if (!filter.search && filter?.myCategories?.length) {
            let isCat = false;

            _.each(filter.myCategories, (categoryId, ind) => {
                if ((item.categories || []).find(v => {
                    return categoryId == v;
                })) {
                    isCat = true
                }


                if (!isCat) {
                    isOk = false;
                    errors.push('category')
                }

            })

        }
        if (!filter.search && (filter.status && filter.status != '0') && (filter.status != (item.status || 'new'))) {
            isOk = false
            errors.push('status')
        }
        if (!filter.search && (filter.smStatus && filter.smStatus != '0') && (filter.smStatus != (item.smartReadingStatus || 'new'))) {
            isOk = false
            errors.push('smStatus')

        }

        console.log("qqqqq isOk", isOk, errors, filter, filter.status);
        let st = item.status || 'new'
        if (isOk) {
            goodItems.push(item)
            counts.good[st] = (counts.good[st] || 0) + 1
        } else {
            otherItems.push(item)
            counts.other[st] = (counts.other[st] || 0) + 1
        }

    })

    function RenderItem(props) {
        let {item, ind} = props;
        let it = item;
        return (<div key={ind} style={{marginBottom: '10px'}}
                     className={selItem?._id == it?._id ? 'bookActive' : ''}
                     onClick={() => {
                         onClickBook(it)
                     }}>

            {!!it.rate && <div className={'mr-5 label label-default'}>{it.rate}</div>}
            <div
                className={"label mr-5  " + (it.smartReadingStatus == 'new' && !it.status ? 'label-info' : 'label-default')}>{it.status}</div>
            <div
            title={'Number of Zam | categories length | SmrViews'}
                className="label mr-5 label-default">{it.numberOfSkills || 'No Skills'} / {it.smartReadingStats?.likes} / {it.smartReadingStats?.views}</div>

            <div>

                {it.name}
            </div>
        </div>)

    }

    let newCount = (counts?.good?.new || 0) + (counts?.other?.new || 0);
    let totalCount = otherItems?.length + goodItems?.length

    return <div className={'row'}>
        <div className="col-xs-4">
            <div className="ib">
                <Input
                    placeholder={'Search ...'}
                    value={filter.search} onChange={search => setFilter({...filter, search})}></Input>
            </div>
            <div className="ib">
                <Select
                    title={'Sort'}
                    items={['', 'cd', 'likes', 'views', 'rate']} value={filter.sort}
                    onChange={sort => setFilter({...filter, sort})}></Select>
            </div>
            <div className="ib">
                <Select
                    title={'MyStatus'}
                    items={['', 'new', 'read', 'great']} value={filter.status}
                    onChange={status => setFilter({...filter, status})}></Select>
            </div>

            <div className="ib">
                <Select
                    title={'SMStatus'}
                    items={['', 'new', 'read']} value={filter.smStatus}
                    onChange={smStatus => setFilter({...filter, smStatus})}></Select>
            </div>
            <div className="ib"
                 style={{width: '100%'}}
            >
                <MyAutoComplete
                    label={'SMCategories'}
                    isMulti={true}
                    items={categories}
                    value={filter.categories}
                    onChange={categories => {
                        console.log("qqqqq categories", categories, filter);
                        setFilter({...filter, categories})
                    }}
                ></MyAutoComplete>
            </div>
            <div className="ib"
                 style={{width: '100%'}}
            >
                <MyAutoComplete
                    label={'MyCategories'}
                    isMulti={true}
                    itemsKey={'categories'}
                    value={filter.myCategories}
                    onChange={myCategories => {
                        console.log("qqqqq categories", myCategories, filter);
                        setFilter({...filter, myCategories})
                    }}
                ></MyAutoComplete>
            </div>
            <div style={{marginTop: '20px'}}></div>
            <span
                title={'new'}>{counts?.good?.new}</span> + <span
            title={'read'}>{(goodItems?.length || 0) - (counts?.good?.new || 0)}</span> = {goodItems?.length} || <span
            title={'new'}>{newCount}</span> + <span title={'read'}>{totalCount - newCount}</span> = {totalCount}
            {/*<Select items={['', 'new', 'read', 'great']} value={filter.status}></Select>*/}
            {!goodItems?.length && <div>
                No Items Found
            </div>}
            {(goodItems || []).map((it, ind) => {
                if (!isFullOpen1 && ind > 50) {
                    return null;
                }

                return <RenderItem item={it} ind={ind}></RenderItem>
            })}
            {goodItems?.length > 50 && <a onClick={() => {
                setIsFullOpen1(!isFullOpen1)
            }}>Toggle {!isFullOpen1 ? goodItems.length : ''}</a>}

            <div style={{margin: '10px 0', background: 'rgba(255, 0, 0, .25)', padding: '10px 2px'}}>
                FULL ITEMS
            </div>

            {!otherItems?.length && <div>
                No Items Found
            </div>}
            {(otherItems || []).map((it, ind) => {
                if (!isFullOpen2 && ind > 30) {
                    return null;
                }
                return <RenderItem item={it} ind={ind}></RenderItem>
            })}
            <a onClick={() => {
                setIsFullOpen2(!isFullOpen2)
            }}>Toggle {!isFullOpen2 ? otherItems.length : ''}</a>
        </div>

        <div className="col-xs-8">
            {selItem?.loading && <>Loading</>}
            {selItem && !selItem?.loading && <>
                <BookShort item={selItem}
                           onClickToOpen={() => {

                           }}
                           onChangeFilter={v => setFilter({...filter, ...v})}
                           onChange={(v) => {
                               global.http.put(`/smart-book`, {...selItem, ...v}).then(r => {
                                   setSelItem(r)
                                   setItems(items.map(it => {
                                       if (it._id == r._id) {
                                           it = _.extend({}, it, r);
                                       }
                                       return it
                                   }))
                                   console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", r.status, r.rate, v.rate, r);
                               })
                           }}
                ></BookShort>
            </>}

        </div>
        {/*{(info?.items || []).map((it, ind) => {*/}
        {/*    if (ind != 1) {*/}
        {/*        return;*/}
        {/*    }*/}
        {/*    return (<div key={ind}>*/}
        {/*        */}
        {/*    </div>)*/}
        {/*})}*/}
    </div>
}

export default Layout2
