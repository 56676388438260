global.is_local = /localhost/.test(window.location.host) ? 1 : 0;

let localDoamin = 'http://localhost:6055'
let remoteAPI = 'https://skapi.itrum.ru'
// let domain = global.is_local ? 'http://localhost:6055' : 'http://80.66.64.58:6055';
let domain = global.is_local ? remoteAPI : 'https://skapi.itrum.ru';
window.env = {
    // domain     : ,
    domain,
    FILE_DOMAIN: domain,
    title: 'Skill Portal',
    login_title: 'Skill Portal',
    wo_token: false,
    redirect_after_login: '/admin/users'

}

document.title = window.env.title;
