import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import Smart from "../../libs/Smart";
import SaveUpdate from "./SaveUpdate";
import {pubAuthor, pubCategory} from "./methods";
import Stringify from "../Stringify";
import {groupItems} from "./Zadachi";
import ZametkiPreview from "./ZametkiPreview";
import ZadachiPreview from "./ZadachiPreview";
import ZadachiEdit from "./ZadachiEdit";
import Select from "../../libs/Select";


function Layout2(props) {
    let {filter, setFilter} = props
    console.log('*........ ## ROOT RENDER', props);

    let [cd, setCd] = useState(null)
    let [selZametka, setSelZametka] = useState(null)
    let category = filter?.category || -1;
    let sortFn = it => {

        let {zametkiSort} = filter || {}
        if (zametkiSort === 'last') {
            return (-1) * new Date(it.cd).getTime();
        }
        if (zametkiSort === '-last') {
            return it.cd;
        }
        console.log('sort zamtki444', zametkiSort)

        if (!zametkiSort || zametkiSort == '0') {
            console.log('sort zamtki')
            return Math.random();
        }

        return (-1) * (it?.info?.level || 1) + (!it.tasks.length ? 0 : 1000) + (it.status == 'archive' ? 100000 : 0) + +(it?.categories?.length ? 10 : 0)
    };

    let [limit, setLimit] = useState(100);
    let v = groupItems(window?.info?.skills || [], filter, sortFn, null, limit)
    let {items, childItems} = v;


    return <div>
        <Button
            size='xs'
            color={4}
            onClick={(cb) => {
                setSelZametka({categories: category > 0 ? [category] : []})
                cb && cb()
            }}>+ Заметка</Button>
        <Button size={'xs'} color={4} onClick={(cb) => {
            setCd(new Date().getTime())
            setFilter({...filter, zametkiSort: ''})
            cb && cb();
        }}>Rend</Button>
        <div className={'ib'} style={{marginTop: '-5px', marginLeft: '5px'}}>
            <Select value={filter.zametkiSort}
                    items={['', 'popularity', 'last', '-last']}
                    onChange={(v) => {
                        filter.zametkiSort = v
                        setFilter({...filter})
                    }}></Select>

        </div>
        <hr/>


        <div className={'vh100'}>
            {(items || []).map((it, ind) => {
                return (<ZametkiPreview ind={ind} item={it} onClick={setSelZametka}></ZametkiPreview>)
            })}
            {!!childItems && !!childItems.length && <div>
                <hr/>
                <div style={{
                    background: 'red',
                    display: 'block',
                    padding: '20px',
                    float: 'left',
                    width: '100%',
                    margin: '10px 0'
                }}>
                    Заметки из дочерних
                </div>
                <hr/>
                {(childItems || []).map((it, ind) => {
                    return <ZametkiPreview ind={ind} item={it} onClick={setSelZametka}></ZametkiPreview>
                })}
            </div>}
            {(items.length + childItems.length) < limit && <div>
                <div style={{
                    background: 'orange',
                    display: 'block',
                    padding: '20px',
                    float: 'left',
                    width: '100%',
                    margin: '10px 0'
                }}>Ничего нет - показываю все
                </div>
                <hr/>
                {_.sortBy((window?.info?.skills || []), sortFn).filter((it, ind) => ind < limit).map((it, ind) => {
                    return <ZametkiPreview ind={ind} item={it} onClick={setSelZametka}></ZametkiPreview>
                })}
            </div>}
        </div>
        <MyModal
            isOpen={selZametka}
            onClose={setSelZametka}
        >
            <ZametkiEdit

                item={selZametka} setItem={setSelZametka}></ZametkiEdit>

        </MyModal>


    </div>
}


export function ZametkiEdit(props) {
    let {item, setItem} = props;
    let [newZadacha, setNewZadacha] = useState(null)

    let selZametka = props.item;
    let setSelZametka = props.setItem
    return <>
        <SaveUpdate
            item={selZametka}
            onChange={() => {
                setSelZametka(false, selZametka)
            }
            }
            url={'skill'}
            field={'skills'}
        ></SaveUpdate>
        <hr/>
        <Smart
            defSize={12}
            obj={selZametka}
            items={[
                {
                    name: 'Статус',
                    key: 'status',
                    type: 'group', items: [{name: 'Актив', value: 'active'}, {name: 'Архив', value: 'archive'}]
                },
                {
                    name: 'Имя', key: 'name',
                }, {
                    name: 'Описание', key: 'desc', type: 'textarea'
                }, {
                    name: 'Img', key: 'img', type: 'textarea', minRows: 1,
                }, {
                    name: 'Img2', key: 'img2', type: 'textarea', minRows: 1,
                }, {
                    size: 6,
                    name: 'Level', key: 'info.level', type: 'group',
                    items: [1, 2, 3, 4, 5].map(it => {
                        return {name: it, value: it, key: it}
                    })
                }, {
                    size: 2,
                    type: 'checkbox',
                    label: 'isPin',
                    key: 'isPin'
                }, {
                    size: 2,
                    type: 'checkbox',
                    label: 'isPin2',
                    key: 'isPin2'
                },{
                    size: 2,
                    type: 'checkbox',
                    label: 'isStory',
                    key: 'isStory'
                },
                {

                    isMulti: true,
                    path: 'Main/MyAutocomplete',
                    key: 'categories',
                    itemsKey: 'categories',
                    label: 'Категория',
                },
                {
                    isMulti: true,
                    path: 'Main/MyAutocomplete',
                    key: 'authors',
                    itemsKey: 'authors',
                    label: 'Автор',
                },
                {

                    isMulti: true,
                    path: 'Main/MyAutocomplete',
                    key: 'tasks',
                    itemsKey: 'tasks',
                    label: 'Задачи',
                },

            ]}
            onChange={(v) => setSelZametka({...v})}
        >

        </Smart>
        <MyModal size={'full'} isOpen={newZadacha} onClose={setNewZadacha}>
            <ZadachiEdit item={newZadacha} onChange={setNewZadacha} onClose={(v) => {
                setSelZametka({...selZametka, tasks: [...selZametka?.tasks || [], v?._id]})
                setNewZadacha(false)
            }}></ZadachiEdit>
        </MyModal>
        <a onClick={() => {
            setNewZadacha({});
        }}>Добавить задачу</a>
        <div className={'forImgZam'}>
            {selZametka?.img && <><img src={selZametka?.img}/></>}
            {selZametka?.img2 && <><img src={selZametka?.img2}/></>}
        </div>

        <hr/>
        <SaveUpdate
            item={selZametka}
            onChange={() => {
                setSelZametka(false, selZametka)
            }
            }
            url={'skill'}
            field={'skills'}
        ></SaveUpdate>
    </>
}

export default Layout2
